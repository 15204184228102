<template>
  <div class="wrapper">
    <!-- 身体 -->
    <div class="main">
      <div class="tkxi">
        退款商品
      </div>
      <div class="flex jc-sb">
        <div class="lf">
          <img :src="details.goodsPic" alt="">
        </div>
        <div class="ri">
          <div class="flex ai-c jc-sb">
            <div class="sz">{{details.goodsName}}</div>
            <div class="my">￥{{details.goodsPrice}}</div>
          </div>
          <div class="flex ai-c jc-sb">
            <div class="sz sza">{{details.goodsAttr}}</div>
            <div class="mya">x{{details.goodsCount}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 退款信息 -->
    <div class="main">
      <div class="tkxi">
        退款信息
      </div>
      <div class="tkyy flex jc-sb ai-c">
        <div class="yi">
          <span>物流单号</span>
          <span style="color:#F35E3A">*</span>
        </div>
        <div class="flex ai-c">
          <!-- <span class="qxz">请填写</span> -->
          <input v-model="deliverySn" :readonly="!isEdit" class="qxz" type="text" placeholder="请填写">
          <!-- <van-icon color="#C9C9C9"
                    size="10"
                    name="arrow" /> -->
        </div>
      </div>
      <div class="tkyy flex jc-sb ai-c"
           @click="pickerClick">
        <div class="yi">
          <span>物流公司</span>
          <span style="color:#F35E3A">*</span>
        </div>
        <div class="flex ai-c">
          <span class="qxz" style="color:#bdbdbd" v-if="deliveryCompany==''">请选择物流公司</span>
          <span class="qxz" v-else>{{deliveryCompany}}</span>
          <van-icon color="#C9C9C9"
            v-if="isEdit"
                    size="10"
                    name="arrow" />
        </div>
      </div>
      <!-- <div class="tkyy flex jc-sb ai-c"
           style="margin-bottom:0;">
        <div class="yi">
          <span>联系电话</span>
          <span style="color:#F35E3A">*</span>
        </div>
        <div class="flex ai-c">
          <input class="qxz" type="text" placeholder="请填写">
        </div>
      </div> -->
    </div>
    <div class="btnsub"
          v-if="isEdit"
         @click="submit">
      提交
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getExpress, putExpress } from '../../../../../api'
export default {
  data() {
    return {
      show: false,
      columns: [],
      details: {},
      deliveryCompany: '',
      deliverySn: '',
      companyId: '',
      isEdit: true
    }
  },
  created() {
    var details = JSON.parse(localStorage.getItem('logisticInfo'))
    this.details = details
    if (details.deliveryCompanySn) {
      this.isEdit = false
      this.deliveryCompany = details.deliveryCompany
      this.deliverySn = details.deliverySn
    }
    this.getExpress()
  },
  methods: {
    pickerClick() {
      if (this.isEdit) {
        this.show = true
      }
    },
    getExpress() {
      getExpress({}).then(res=>{
        this.columns = res.data.data
      })
    },
    onConfirm(value) {
      this.deliveryCompany = value.name;
      this.companyId = value.id
      this.show = false;
    },
    submit() {
      if (this.deliverySn=='') {
        return this.$toast('请输入物流单号')
      }
      if (this.companyId=='') {
        return this.$toast('请输入物流公司')
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true
      })
      putExpress(this.$route.query.orderItemId,this.companyId,this.deliverySn).then(res=>{
        this.$toast.success({
          message: '提交成功'
        })
        this.$router.go(-1)
      }).catch(()=>{
        this.$toast.clear()
      })
    }
  }
}
</script>

<style scoped>
.wrapper {
  /* height: 100vh;
  position: relative; */
}
.btnsub {
  width: 6.98rem;
  height: 0.92rem;
  line-height: 0.92rem;
  text-align: center;
  background: #f35e3a;
  border-radius: 1rem;
  font-size: 0.36rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0.3rem;
}
.main .qxz {
  width: 4.5rem;
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgb(59, 59, 59);
  margin-right: 0.2rem;
  text-align: right;
  border: 0;
}
.main .yi {
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
}
.main .tkyy {
  margin-bottom: 0.38rem;
}
.main .my {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.main .sz {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.main .sza {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
  background: #f4f4f4;
}
.main .mya {
  font-size: 0.24rem;
  color: #aaaaaa;
}
.main .ri {
  width: calc(100% - 1.76rem);
}
.main .lf {
  width: 1.54rem;
  height: 1.54rem;
  background: #f4f4f4;
  border: 1px solid #eeeeee;
  border-radius: 0.01rem;
}
.main .lf img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main {
  background-color: #fff;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.3rem;
}
.main .tkxi {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-bottom: 0.4rem;
}
</style>
